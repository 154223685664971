<template>
  <div class="support">
    <div class="section section-1">
      <b-container>
        <b-row class="align-items-center">
          <b-col cols="12" class="text-center">
            <h2 class="title">Contact us</h2>
            <p class="mini-title text-center">
              There are plenty of ways to get in touch with us, wherever you are in the world.
            </p>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="section section-2">
      <b-container>
        <div class="contact-from">
          <b-form>
            <p>Send us a message</p>
            <p>(Response within 24 business hours)</p>
            <!-- Fullname -->
            <b-form-group id="input-group-1" label="Full Name*" label-for="input-1">
              <b-form-input
                id="input-1"
                v-model="formContact.fullName"
                type="email"
                placeholder="Enter your fullname"
                required
              ></b-form-input>
            </b-form-group>
            <!-- Email -->
            <b-form-group id="input-group-1" label="Email Address*" label-for="input-1">
              <b-form-input
                id="input-1"
                v-model="formContact.email"
                type="email"
                placeholder="Enter your email"
                required
              ></b-form-input>
            </b-form-group>
            <!-- Phone number -->
            <b-form-group id="input-group-1" label="Phone number:" label-for="input-1">
              <b-form-input
                id="input-1"
                v-model="formContact.phone"
                type="email"
                placeholder="Enter your phone number"
              ></b-form-input>
            </b-form-group>
            <!-- Request -->
            <b-form-group id="input-group-1" label="Your request*" label-for="input-1">
              <b-form-input
                id="input-1"
                v-model="formContact.request"
                type="email"
                placeholder="Enter your request"
                required
              ></b-form-input>
            </b-form-group>
            <div class="btn-contact">
              <b-button type="submit" variant="success">Send</b-button>
            </div>
          </b-form>
        </div>
      </b-container>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      formContact: {
        fullName: '',
        email: '',
        phone: '',
        request: '',
      },
    };
  },
};
</script>

<style lang="scss">
.contact-from {
  p {
    text-align: center;
    &:nth-child(1) {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 0px;
      color: #009750;
    }
    &:nth-child(2) {
      font-size: 14px;
    }
  }
  .btn-contact {
    width: 100%;
    text-align: center;
    margin: 20px 0px;
    button {
      border-radius: 20px;
    }
  }
}
.support {
  h2.title {
    font-size: clamp(1.2rem, 3vw, 2.2rem);
  }
  .section-1 {
    padding: 150px 0 20px;
    background: #f2f4f6;
    position: relative;
    @media (max-width: 1440px) {
      padding: 120px 0 20px;
    }
  }
  .section-2 {
    padding: 20px 0 10px;
    position: relative;
    padding: 1rem;
    margin: 1rem -15px 0;
    border: solid #f8f9fa;
    border-width: 0.2rem 0 0;
    .container {
      max-width: 768px;
    }
  }
}
</style>
